<template>
  <section>
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter On"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          description="Leave all unchecked to filter on all data"
          class="mb-0"
        >
          <b-form-checkbox-group v-model="filterOn" class="">
            <b-form-checkbox value="orderNo" class="">Order no</b-form-checkbox>
            <b-form-checkbox value="projectNo">Project no</b-form-checkbox>
            <b-form-checkbox value="projectName">Project</b-form-checkbox>
            <b-form-checkbox value="statusName">Status</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="7" md="6" class="my-1" v-if="totalRows > perPage">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- filter-included-field -->
    <b-table
      hover
      striped
      bordered
      head-variant="dark"
      sticky-header
      outlined
      :items="projects"
      :fields="fields"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :current-page="currentPage"
      :per-page="perPage"
      @filtered="onFiltered"
      responsive="sm"
    >
      <template #cell(orderNo)="data">
        <router-link
          class="btn btn-link btn-sm mr-2"
          :to="'/order/view/' + data.item.id"
          >{{ data.item.orderNo }}</router-link
        >
      </template>

      <template #cell(status)="data">
        <Status-Badge
          :status="data.item.statusName"
          v-if="data.item.statusName"
        ></Status-Badge>
      </template>

      <template #cell(numberoflines)="data">
        {{ data.item.orderLines.length }}
      </template>

      <template #cell(created)="data">
        {{ data.value | formatDate }}
      </template>

      <template #cell(edit)="data">
        <router-link
          class="btn btn-primary btn-sm mr-2"
          :to="'/order/view/' + data.item.id"
          >{{ $t("items.view") }}</router-link
        >
      </template>
    </b-table>
  </section>
</template>

<script>
import StatusBadge from "@/components/StatusBadge";

export default {
  name: "OrdersTable",
  components: {
    StatusBadge
  },
  props: ["projects"],
  data() {
    return {
      searchString: "",
      fields: [
        {
          key: "orderNo",
          label: "Order no",
          sortable: true
        },
        // {
        //     key: 'projectNo',
        //     label: 'Project no',
        //     sortable: true
        // },
        {
          key: "projectName",
          label: "Project",
          sortable: true
        },
        // {
        //     key: 'description'
        // },
        {
          key: "status",
          sortable: true
        },
        {
          key: "numberoflines",
          label: "Number of lines",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
        {
          key: "orderedBy",
          label: "Ordered by",
          sortable: true
        },
        {
          key: "created",
          sortable: true
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger'
        },
        "edit"
      ],
      filter: null,
      filterOn: [],
      sortBy: "created",
      sortDesc: true,
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      pageOptions: [10, 20, 50, 100]
    };
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
  created() {
    this.totalRows = this.$props.projects.length || 1;
  }
};
</script>

<style>
.custom-control-label {
  margin-top: 8px;
}
</style>
