<template>
  <div>
    <!-- spinner -->
    <b-alert v-if="error" show variant="danger">{{ error.statusText }}</b-alert>
    <div v-if="isLoading && !error" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <section v-else>
      <div class="card" v-if="showTable">
        <header class="card-header">
          {{ $t("order.my") }}
          <span class="pull-right">
            <router-link
              :to="{ name: 'neworder' }"
              class="btn btn-success btn-sm"
              >{{ $t("links.order") }}
            </router-link>
          </span>
        </header>
        <Orders-Table
          :projects="getOrders.filter(x => x.createdBy === user.id)"
        ></Orders-Table>
      </div>
      <!-- Message board if now order exists -->
      <div v-else>
        <b-card header="Welcome">
          <p>{{ $t("order.welcome") }}</p>
          <router-link
            :to="{ name: 'neworder' }"
            class="btn btn-success btn-sm"
            >{{ $t("links.order") }}</router-link
          >
        </b-card>
      </div>
    </section>
    <!-- page end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OrdersTable from "@/components/OrdersTable.vue";

export default {
  name: "my",
  components: {
    OrdersTable
  },
  data() {
    return {
      user: {},
      error: null,
      showTable: false
    };
  },
  computed: {
    ...mapGetters(["getOrders", "isLoading", "getUser"])
  },
  methods: {
    ...mapActions(["loadOrders"])
  },
  created() {
    this.loadOrders()
      .then(() => {
        if (
          Object.keys(this.getOrders.filter(x => x.createdBy === this.user.id))
            .length > 0
        ) {
          this.showTable = true;
        } else {
          this.showTable = false;
        }
      })
      .catch(error => {
        this.error = error;
      });
    this.user = this.getUser;
  }
};
</script>

<style></style>
